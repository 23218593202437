<template>
    <div class="cryptocurrency-exchange-questions">
        <h1 class="cryptocurrency-exchange__title">
            <span class="cryptocurrency-exchange__theme_blue">
                KYC <span class="font-ligth">/</span> AML
            </span>
        </h1>

        <details class="cryptocurrency-exchange-question">
            <summary class="cryptocurrency-exchange-question__title">
                <span>
                    KYC verification: what it is and what it's for
                </span>
                <svg class="cryptocurrency-exchange__calculator-select-icon cryptocurrency-exchange-question__title-arrow-icon"
                    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                    <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                </svg>
            </summary>

            <div class="cryptocurrency-exchange-question__content">
                <ol class="cryptocurrency-exchange-question__content-items">
                    <li class="cryptocurrency-exchange-question__content-item">
                        What is KYC verification and why does it get so much attention? We'll tell you how it can keep
                        you away from scammers while preserving your anonymity. KYC and AML verification. Why identity
                        verification is required in the sphere of cryptocurrency. KYC (Know Your Customer) is a
                        procedure for verifying your customer's identity and assessing potential risks. But what is it
                        for and why is it almost impossible nowadays to buy cryptocurrency without confirming your
                        identity? Doesn't this go against the original principles of anonymity and decentralization in
                        the world of cryptocurrencies? Today we'll look at why AML and KYC verifications exist and how
                        they work. We will also tell you how verification helps to reduce the number of scammers while
                        maintaining users' basic anonymity.
                    </li>
                </ol>
            </div>
        </details>
        <details class="cryptocurrency-exchange-question">
            <summary class="cryptocurrency-exchange-question__title">
                <span>
                    What is AML and what is it for?
                </span>
                <svg class="cryptocurrency-exchange__calculator-select-icon cryptocurrency-exchange-question__title-arrow-icon"
                    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                    <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                </svg>
            </summary>

            <div class="cryptocurrency-exchange-question__content">
                <ol class="cryptocurrency-exchange-question__content-items">
                    <li class="cryptocurrency-exchange-question__content-item">
                        Anti-Money Laundering is a complex of measures to counter money laundering, financing of
                        terrorism, and weapons of mass destruction. This involves the identification, storage, and
                        mutual exchange of information about customers, their income, and transactions between financial
                        organizations and government agencies. Most classic financial institutions use AML measures to
                        verify businesses that operate with cash or use cash as their core asset. They also check those
                        businesses that keep money in different accounts, transferring it regularly between countries
                        and banks, buying futures and other cash settlement tools. That is to say, all businesses that
                        can potentially circumvent financial monitoring and launder funds are put under verification.
                    </li>
                </ol>
            </div>
        </details>
        <details class="cryptocurrency-exchange-question">
            <summary class="cryptocurrency-exchange-question__title">
                <span>
                    What does the address verification show?
                </span>
                <svg class="cryptocurrency-exchange__calculator-select-icon cryptocurrency-exchange-question__title-arrow-icon"
                    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                    <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                </svg>
            </summary>

            <div class="cryptocurrency-exchange-question__content">
                <ol class="cryptocurrency-exchange-question__content-items">
                    <li class="cryptocurrency-exchange-question__content-item">
                        It displays the overall risk (in percent), meaning the probability that the address is
                        associated with illegal activities. Risk sources are the known types of exchange services with
                        which the address has interacted. As well as the percentage of funds received or sent from the
                        services for which the total risk is calculated.
                    </li>
                </ol>
            </div>
        </details>
        <details class="cryptocurrency-exchange-question">
            <summary class="cryptocurrency-exchange-question__title">
                <span>
                    Why do exchange services insist on AML verifications?
                </span>
                <svg class="cryptocurrency-exchange__calculator-select-icon cryptocurrency-exchange-question__title-arrow-icon"
                    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                    <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                </svg>
            </summary>

            <div class="cryptocurrency-exchange-question__content">
                <ol class="cryptocurrency-exchange-question__content-items">
                    <li class="cryptocurrency-exchange-question__content-item">
                        Without these verifications, fraudsters can use the service as a platform for money laundering
                        and terrorism financing. Then the service itself will be held liable for that. This is why
                        exchanges and other major cryptocurrency companies implement AML regulations and conduct regular
                        KYC verifications.
                    </li>
                </ol>
            </div>
        </details>
        <details class="cryptocurrency-exchange-question">
            <summary class="cryptocurrency-exchange-question__title">
                <span>
                    How can a risk assessment be evaluated?
                </span>
                <svg class="cryptocurrency-exchange__calculator-select-icon cryptocurrency-exchange-question__title-arrow-icon"
                    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                    <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                </svg>
            </summary>

            <div class="cryptocurrency-exchange-question__content">
                <ol class="cryptocurrency-exchange-question__content-items">
                    <li class="cryptocurrency-exchange-question__content-item">
                        - 0-25% - safe wallet/transaction;
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        - 25-75% - an average risk level;
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        - 75%+ - the wallet/transaction is considered as risky.
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        What if the indicated risk is over 50%, but I'm sure the address is reliable? What should I do?
                        The verification results are based on international databases that are getting updated
                        constantly. The address that was assessed as 0% risk yesterday could receive or give the asset
                        to a risky counterparty today. In this case, the risk assessment will change.
                    </li>
                </ol>
            </div>
        </details>
    </div>
</template>

<script>
export default {
    name: 'AmlVue',
    data: () => ({}),
    methods: {},
}
</script>
<style></style>