
<template>

    <div class="cryptocurrency-exchange">
        <h1 class="cryptocurrency-exchange__title">
            Swift <span class="cryptocurrency-exchange__theme_blue">cryptocurrency</span> exchange
        </h1>

        <span class="cryptocurrency-exchange__subtitle">
            24/7 quick and secure exchange for our customers
        </span>
    </div>
    <div class="wrapper-cryptocurrency-exchange__calculator">
        <div class="wrapper-cryptocurrency-exchange__calculator_inner">
            <div class="cryptocurrency-exchange__calculator">
                <div class="wrapper-cryptocurrency-exchange__calculator-input">
                    <span class="cryptocurrency-exchange__calculator-title">
                        You exchange
                    </span>

                    <input шв class="cryptocurrency-exchange__calculator-input" type="text"
                        name="cryptocurrency-exchange__calculator-input" id="cryptocurrency-exchange__calculator-input-exchange"
                        v-model="exchangeInputValue" @input="changeExchangeValue">
                </div>
                <button type="button" class="wrapper-cryptocurrency-exchange__calculator-select"
                    :class="{ 'wrapper-cryptocurrency-exchange__calculator-select-active': showListCoinsForExchange }"
                    @click="showListCoinsForExchangeMethod">
                    <div class="cryptocurrency-exchange__calculator-select-coin" v-if="selectedExchangeCoin">
                        <img class="cryptocurrency-exchange__calculator-select-coin-icon"
                            :src="selectedExchangeCoin.src" :alt="selectedExchangeCoin.alt">
                        <span class="cryptocurrency-exchange__calculator-select-coin-name">{{
                            selectedExchangeCoin.shortName }}</span>
                    </div>

                    <svg class="cryptocurrency-exchange__calculator-select-icon cryptocurrency-exchange__calculator-select-icon-absolute"
                        fill="#969fa8" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                        <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                    </svg>
                </button>

                <div class="wrapper-cryptocurrency-exchange__calculator-list"
                    :style="{ 'display': showListCoinsForExchange ? 'block' : 'none' }">
                    <div class="cryptocurrency-exchange__calculator-list">
                        <div class="cryptocurrency-exchange__calculator-list-search">
                            <input type="text" name="cryptocurrency-exchange__calculator-list-search-input"
                                v-click-outside
                                class="cryptocurrency-exchange__calculator-list-search-input"
                                placeholder="Enter the name of the payment system" autocomplete="off"
                                @input="(event) => filterListCoins('coinsForExchange', event.target.value)">
                        </div>
                        <ul class="wrapper-cryptocurrency-exchange__calculator-list-coins">
                            <li class="cryptocurrency-exchange__calculator-list-coin"
                                v-for="coinForExchange in listCoinsForExchangeCopy" :key="coinForExchange.id"
                                @click="setSelectedExchangeCoinMethod(coinForExchange)">
                                <div class="cryptocurrency-exchange__calculator-select-coin">
                                    <img class="cryptocurrency-exchange__calculator-select-coin-icon"
                                        :src="coinForExchange.src" :alt="coinForExchange.alt">
                                    <span class="cryptocurrency-exchange__calculator-select-coin-name">
                                        {{ coinForExchange.name }}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="cryptocurrency-exchange__calculator">
                <div class="wrapper-cryptocurrency-exchange__calculator-input">
                    <span class="cryptocurrency-exchange__calculator-title">
                        You get
                    </span>

                    <input class="cryptocurrency-exchange__calculator-input" type="text"
                        name="cryptocurrency-exchange__calculator-input" id="cryptocurrency-exchange__calculator-input-get"
                        autocomplete="off" v-model="getInputValue" @input="changeGetValue">
                </div>
                <button type="button" class="wrapper-cryptocurrency-exchange__calculator-select"
                    :class="{ 'wrapper-cryptocurrency-exchange__calculator-select-active': showListCoinsForGet }"
                    @click="showListCoinsForGetMethod">
                    <div class="cryptocurrency-exchange__calculator-select-coin" v-if="selectedGetCoin">
                        <img class="cryptocurrency-exchange__calculator-select-coin-icon" :src="selectedGetCoin.src"
                            :alt="selectedGetCoin.alt">
                        <span class="cryptocurrency-exchange__calculator-select-coin-name">{{
                            selectedGetCoin.shortName }}</span>
                    </div>

                    <svg class="cryptocurrency-exchange__calculator-select-icon cryptocurrency-exchange__calculator-select-icon-absolute"
                        fill="#969fa8" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                        <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                    </svg>
                </button>

                <div class="wrapper-cryptocurrency-exchange__calculator-list"
                    :style="{ 'display': showListCoinsForGet ? 'block' : 'none' }">
                    <div class="cryptocurrency-exchange__calculator-list">
                        <div class="cryptocurrency-exchange__calculator-list-search">
                            <input type="text" name="cryptocurrency-exchange__calculator-list-search-input"
                                class="cryptocurrency-exchange__calculator-list-search-input"
                                placeholder="Enter the name of the payment system" autocomplete="off"
                                @input="(event) => filterListCoins('coinsForGet', event.target.value)">
                        </div>
                        <ul class="wrapper-cryptocurrency-exchange__calculator-list-coins">
                            <li class="cryptocurrency-exchange__calculator-list-coin"
                                v-for="coinForGet in listCoinsForGetCopy" :key="coinForGet.id"
                                @click="setSelectedGetCoinMethod(coinForGet)">
                                <div class="cryptocurrency-exchange__calculator-select-coin">
                                    <img class="cryptocurrency-exchange__calculator-select-coin-icon"
                                        :src="coinForGet.src" :alt="coinForGet.alt">
                                    <span class="cryptocurrency-exchange__calculator-select-coin-name">
                                        {{ coinForGet.name }}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <button type="button" class="cryptocurrency-exchange__calculator-button"
                @click="showAccountDetailsFormMethod" v-if="!showAccountDetailsForm">
                Exchange
            </button>
        </div>
    </div>
    <div class="cryptocurrency-exchange-info" v-if="!showAccountDetailsForm">
        <h1 class="cryptocurrency-exchange__title">
            Why choosing <span class="cryptocurrency-exchange__theme_blue">cryptoexchanger24?</span>
        </h1>

        <div class="wrapper-cryptocurrency-exchange-info__cards">
            <div class="cryptocurrency-exchange-info__card">
                <div class="wrapper-cryptocurrency-exchange-info__card-img">
                    <img class="cryptocurrency-exchange-info__card-img" src="../../public/img/fast.jpeg"
                        alt="info__card-img">
                </div>
                <div class="cryptocurrency-exchange-info__card-subtitle">
                    It's Fast
                </div>
                <div class="cryptocurrency-exchange-info__card-text">
                    Top exchange speeds due to complete automization
                </div>
            </div>
            <div class="cryptocurrency-exchange-info__card">
                <div class="wrapper-cryptocurrency-exchange-info__card-img">
                    <img class="cryptocurrency-exchange-info__card-img" src="../../public/img/safely.jpeg"
                        alt="info__card-img">
                </div>
                <div class="cryptocurrency-exchange-info__card-subtitle">
                    It's Reliable
                </div>
                <div class="cryptocurrency-exchange-info__card-text">
                    Maximum exchange reliability due to the impeccable reputation and extensive experience of our service
                </div>
            </div>
            <div class="cryptocurrency-exchange-info__card">
                <div class="wrapper-cryptocurrency-exchange-info__card-img">
                    <img class="cryptocurrency-exchange-info__card-img" src="../../public/img/round-the-clock.jpeg"
                        alt="info__card-img">
                </div>
                <div class="cryptocurrency-exchange-info__card-subtitle">
                    24/7 Support
                </div>
                <div class="cryptocurrency-exchange-info__card-text">
                    Our consultants are available around the clock to provide assistance in resolving any issues
                </div>
            </div>
        </div>
    </div>
    <div class="cryptocurrency-exchange-questions" v-if="!showAccountDetailsForm">
        <h1 class="cryptocurrency-exchange__title">
            <span class="cryptocurrency-exchange__theme_blue">
                FAQ
            </span>
        </h1>

        <details class="cryptocurrency-exchange-question">
            <summary class="cryptocurrency-exchange-question__title">
                <span>
                    What is a referral program and how can you make money with it?
                </span>
                <svg class="cryptocurrency-exchange__calculator-select-icon cryptocurrency-exchange-question__title-arrow-icon"
                    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                    <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                </svg>
            </summary>

            <div class="cryptocurrency-exchange-question__content">
                <ol class="cryptocurrency-exchange-question__content-items">
                    <li class="cryptocurrency-exchange-question__content-item">
                        A referral program is a program where you earn interest, from the exchanges made
                        by the customers you refer. It means that we are ready to share the profit with
                        you. Earn money along with us. How can you do it? Tell about us, and share your
                        personal referral link with your friends and acquaintances. Put our ads on your
                        website and get a percentage from the exchange operations of the referred users.
                        How to join the program? It's quite easy. First, you need to sign up on our
                        website. You will receive your login and password by e-mail. Your referral link
                        will appear in your personal account. The main point is that the referral
                        program only works if your Referral follows your referral link.
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        How to join the program? It's quite easy. First, you need to sign up on our
                        website. You will receive your login and password by e-mail. Your referral link
                        will appear in your personal account. The main point is that the referral
                        program only works if your Referral follows your referral link.
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        Your commission depends on the number of customers you refer:
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        0,5% from 1 to 20 referrals
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        0,7% from 21 to 70 referrals
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        0.9% over 71 referrals
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        Your commission is calculated depending on the amount of currency exchange of
                        your referral. In case the service has no income from the exchange, your
                        affiliate commission cannot be credited. To withdraw your affiliate commission,
                        the number of unique clients you attracted must exceed 3. Affiliate payments can
                        be paid by any method provided by the exchange service. The minimum withdrawal
                        amount of your affiliate commission is 3 USD or 150 RUB.
                    </li>
                </ol>
            </div>
        </details>
        <details class="cryptocurrency-exchange-question">
            <summary class="cryptocurrency-exchange-question__title">
                <span>
                    Where can I track my application status?
                </span>
                <svg class="cryptocurrency-exchange__calculator-select-icon cryptocurrency-exchange-question__title-arrow-icon"
                    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                    <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                </svg>
            </summary>

            <div class="cryptocurrency-exchange-question__content">
                <ol class="cryptocurrency-exchange-question__content-items">
                    <li class="cryptocurrency-exchange-question__content-item">
                        You can track your application status in your personal account in the 'applications' section
                        (login and password are sent to your e-mail during registration or when you create your first
                        application). We will also notify you about the status of your application by e-mail.
                    </li>
                </ol>
            </div>
        </details>
        <details class="cryptocurrency-exchange-question">
            <summary class="cryptocurrency-exchange-question__title">
                <span>
                    Why do you need confirmations in the bitcoin system?
                </span>
                <svg class="cryptocurrency-exchange__calculator-select-icon cryptocurrency-exchange-question__title-arrow-icon"
                    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                    <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                </svg>
            </summary>

            <div class="cryptocurrency-exchange-question__content">
                <ol class="cryptocurrency-exchange-question__content-items">
                    <li class="cryptocurrency-exchange-question__content-item">
                        On receiving bitcoins, the recipient cannot use them right away. Once a transfer is made, it is
                        sent to the Bitcoin system for execution, pending inclusion into the block and confirmation. The
                        process of including a transfer in a block is called transaction confirmation. Inclusion in 1
                        block = 1 confirmation. When there are 2 or more confirmations, the transaction is considered
                        confirmed, and cryptoexchanger24.com operators, in turn, process the application. This method is
                        introduced for the protection against reuse of the same bitcoins.
                    </li>
                </ol>
            </div>
        </details>
    </div>
    <div class="wrapper-cryptocurrency-exchange-reviews" v-if="!showAccountDetailsForm">
        <h1 class="cryptocurrency-exchange__title">
            Reviews
        </h1>

        <div class="cryptocurrency-exchange-reviews">
            <div class="cryptocurrency-exchange-review">
                <div class="wrapper-cryptocurrency-exchange-review__person-info">
                    <svg class="wrapper-cryptocurrency-exchange-review__person-info-icon" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 512 512" xml:space="preserve" fill="#54a6ff">
                        <g>
                            <path class="st0"
                                d="M159.131,169.721c5.635,58.338,43.367,96.867,96.871,96.867c53.502,0,91.23-38.53,96.867-96.867l7.988-63.029 C365.812,44.768,315.281,0,256.002,0c-59.281,0-109.812,44.768-104.86,106.692L159.131,169.721z" />
                            <path class="st0"
                                d="M463.213,422.569l-3.824-24.35c-3.203-20.417-16.035-38.042-34.475-47.361l-80.473-40.693 c-2.519-1.274-4.57-3.194-6.289-5.338c-23.297,24.632-51.6,39.12-82.15,39.12c-30.549,0-58.856-14.488-82.152-39.12 c-1.719,2.144-3.77,4.064-6.289,5.338l-80.472,40.693c-18.442,9.319-31.272,26.944-34.475,47.361l-3.826,24.35 c-1.363,8.692,0.436,21.448,8.222,27.825C67.42,458.907,105.875,512,256.002,512c150.125,0,188.578-53.093,198.988-61.606 C462.779,444.017,464.576,431.261,463.213,422.569z" />
                        </g>
                    </svg>
                    <div class="cryptocurrency-exchange-review__person-info">
                        <div class="cryptocurrency-exchange-review__person-info-name">
                            Alex
                        </div>
                        <!-- <div class="cryptocurrency-exchange-review__person-info-status">
                            <span class="cryptocurrency-exchange__theme_blue">
                                BestChange
                            </span>
                        </div> -->
                    </div>
                </div>
                <div class="cryptocurrency-exchange-review__person-comment">
                    Best course. I always use this exchanger.
                </div>
                <div class="cryptocurrency-exchange-review__person-comment-date">
                    10.04.2024
                </div>
            </div>
            <div class="cryptocurrency-exchange-review">
                <div class="wrapper-cryptocurrency-exchange-review__person-info">
                    <svg class="wrapper-cryptocurrency-exchange-review__person-info-icon" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 512 512" xml:space="preserve" fill="#54a6ff">
                        <g>
                            <path class="st0"
                                d="M159.131,169.721c5.635,58.338,43.367,96.867,96.871,96.867c53.502,0,91.23-38.53,96.867-96.867l7.988-63.029 C365.812,44.768,315.281,0,256.002,0c-59.281,0-109.812,44.768-104.86,106.692L159.131,169.721z" />
                            <path class="st0"
                                d="M463.213,422.569l-3.824-24.35c-3.203-20.417-16.035-38.042-34.475-47.361l-80.473-40.693 c-2.519-1.274-4.57-3.194-6.289-5.338c-23.297,24.632-51.6,39.12-82.15,39.12c-30.549,0-58.856-14.488-82.152-39.12 c-1.719,2.144-3.77,4.064-6.289,5.338l-80.472,40.693c-18.442,9.319-31.272,26.944-34.475,47.361l-3.826,24.35 c-1.363,8.692,0.436,21.448,8.222,27.825C67.42,458.907,105.875,512,256.002,512c150.125,0,188.578-53.093,198.988-61.606 C462.779,444.017,464.576,431.261,463.213,422.569z" />
                        </g>
                    </svg>
                    <div class="cryptocurrency-exchange-review__person-info">
                        <div class="cryptocurrency-exchange-review__person-info-name">
                            Andrew
                        </div>
                        <!-- <div class="cryptocurrency-exchange-review__person-info-status">
                            <span class="cryptocurrency-exchange__theme_blue">
                                BestChange
                            </span>
                        </div> -->
                    </div>
                </div>
                <div class="cryptocurrency-exchange-review__person-comment">
                    I'm happy with the exchange, next time I'll change it here.
                </div>
                <div class="cryptocurrency-exchange-review__person-comment-date">
                    08.04.2024
                </div>
            </div>
            <div class="cryptocurrency-exchange-review">
                <div class="wrapper-cryptocurrency-exchange-review__person-info">
                    <svg class="wrapper-cryptocurrency-exchange-review__person-info-icon" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 512 512" xml:space="preserve" fill="#54a6ff">
                        <g>
                            <path class="st0"
                                d="M159.131,169.721c5.635,58.338,43.367,96.867,96.871,96.867c53.502,0,91.23-38.53,96.867-96.867l7.988-63.029 C365.812,44.768,315.281,0,256.002,0c-59.281,0-109.812,44.768-104.86,106.692L159.131,169.721z" />
                            <path class="st0"
                                d="M463.213,422.569l-3.824-24.35c-3.203-20.417-16.035-38.042-34.475-47.361l-80.473-40.693 c-2.519-1.274-4.57-3.194-6.289-5.338c-23.297,24.632-51.6,39.12-82.15,39.12c-30.549,0-58.856-14.488-82.152-39.12 c-1.719,2.144-3.77,4.064-6.289,5.338l-80.472,40.693c-18.442,9.319-31.272,26.944-34.475,47.361l-3.826,24.35 c-1.363,8.692,0.436,21.448,8.222,27.825C67.42,458.907,105.875,512,256.002,512c150.125,0,188.578-53.093,198.988-61.606 C462.779,444.017,464.576,431.261,463.213,422.569z" />
                        </g>
                    </svg>
                    <div class="cryptocurrency-exchange-review__person-info">
                        <div class="cryptocurrency-exchange-review__person-info-name">
                            Brian
                        </div>
                        <!-- <div class="cryptocurrency-exchange-review__person-info-status">
                            <span class="cryptocurrency-exchange__theme_blue">
                                BestChange
                            </span>
                        </div> -->
                    </div>
                </div>
                <div class="cryptocurrency-exchange-review__person-comment">
                    Profitable, fast.
                </div>
                <div class="cryptocurrency-exchange-review__person-comment-date">
                    08.04.2024
                </div>
            </div>
        </div>

        <!-- <a href="" class="cryptocurrency-exchange-reviews__link">
            Read reviews on BestChange
        </a> -->
    </div>
    <div class="wrapper-cryptocurrency-exchange-account-details" v-if="showAccountDetailsForm">
        <div class="cryptocurrency-exchange-account-details">
            <h2 class="cryptocurrency-exchange-account-details__title">
                Enter account details
            </h2>

            <form class="cryptocurrency-exchange-account-details__form">
                <div class="cryptocurrency-exchange-account-details__form-inputs">
                    <div class="wrapper-cryptocurrency-exchange-account-details__form-input">
                        <input class="cryptocurrency-exchange-account-details__form-input" type="email"
                            placeholder="E-mail" autocomplete="off"
                            @input="(event) => setUserEmail(event.target.value)">

                        <div class="text-danger" v-if="!errors.email.valid">
                            <span>
                                {{ errors.email.validateMessage }}
                            </span>
                        </div>
                    </div>
                    <div class="wrapper-cryptocurrency-exchange-account-details__form-input">
                        <input class="cryptocurrency-exchange-account-details__form-input" type="text"
                            :placeholder="selectedGetCoin?.placeholder" autocomplete="off"
                            @input="(event) => setUserWallet(event.target.value)">

                        <div class="text-danger" v-if="!errors.wallet.valid">
                            <span>
                                {{ errors.wallet.validateMessage }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="cryptocurrency-exchange-account-details__form-buttons">
                    <button class="cryptocurrency-exchange__calculator-button" type="button" :disabled="!this.isAgree && this.errors.email.valid && this.errors.email.valid"
                        @click="afterCreateOrder()">
                        Go to payment form
                    </button>
                    <label for="cryptocurrency-exchange-account-details__form-button-agree"
                        class="cryptocurrency-exchange-account-details__form-button-agree">
                        <input type="checkbox" id="cryptocurrency-exchange-account-details__form-button-agree"
                            class="cryptocurrency-exchange-account-details__form-button-agree-checkbox"
                            v-model="isAgree">
                        <span>I agree with
                            <router-link to="rules" class="cryptocurrency-exchange__theme_blue">
                                the exchange terms
                            </router-link>
                            and
                            <router-link to="aml" class="cryptocurrency-exchange__theme_blue">
                                AML policies
                            </router-link>
                        </span>
                    </label>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import router from '@/router/index';

export default {
    name: 'MainVue',
    data: () => ({
        showListCoinsForExchange: false,
        showListCoinsForGet: false,
        isAgree: false,
        listCoinsForExchangeCopy: [],
        listCoinsForGetCopy: [],
        exchangeInputValue: null,
        getInputValue: null,
        // eslint-disable-next-line no-useless-escape
        emailValidator: new RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu),
        errors: {
            email: {
                valid: true,
                validateMessage: 'The field must be filled in correctly',
            },
            wallet: {
                valid: true,
                validateMessage: 'This field is required',
            },
        },
    }),
    methods: {
        ...mapMutations([
            'updateSelectedExchangeCoin',
            'updateSelectedGetCoin',
            'updateShowAccountDetailsForm',
            'updateUserEmail',
            'updateUserWallet',
            'updateExchangeValue',
            'updateGetValue',
        ]),
        ...mapGetters([
            'getSelectedExchangeCoin',
            'getSelectedGetCoin',
            'getShowAccountDetailsForm',
            'getListCoinsForExchange',
            'getListCoinsForGet',
        ]),
        ...mapActions([
            'createOrder',
        ]),
        showListCoinsForExchangeMethod() {
            this.showListCoinsForExchange = !this.showListCoinsForExchange;

            if (this.showListCoinsForGet) {
                this.showListCoinsForGet = !this.showListCoinsForGet;
            }
        },
        showListCoinsForGetMethod() {
            this.showListCoinsForGet = !this.showListCoinsForGet;
        },
        showAccountDetailsFormMethod() {
            this.updateShowAccountDetailsForm(!this.showAccountDetailsForm);
        },
        setSelectedExchangeCoinMethod(coin) {
            const minExchangeValue = parseFloat(coin.minValue);
            this.updateSelectedExchangeCoin(coin);
            this.updateExchangeValue(minExchangeValue);
            this.exchangeInputValue = minExchangeValue;
            this.filterListsCoinsAfterSelectCoin();
            this.showListCoinsForExchangeMethod();
            this.calculationExchange('coinsForExchange');
        },
        setSelectedGetCoinMethod(coin) {
            this.updateSelectedGetCoin(coin);
            this.filterListsCoinsAfterSelectCoin();
            this.showListCoinsForGetMethod();
            this.calculationExchange('coinsForGet');
        },
        setUserEmail(email) {
            if (this.emailValidator.test(email)) {
                this.errors.email.valid = true;
                this.updateUserEmail(email);

                return;
            }

            this.errors.email.valid = false;
        },
        setUserWallet(wallet) {
            this.updateUserWallet(wallet);

            if (wallet) {
                this.errors.wallet.valid = true;

                return;
            }

            this.errors.wallet.valid = false;
        },
        filterListsCoinsAfterSelectCoin() {
            this.listCoinsForExchangeCopy = this.getListCoinsForExchange().filter(coinForExchange => this.selectedGetCoin.id !== coinForExchange.id);
            this.listCoinsForGetCopy = this.getListCoinsForGet().filter(coinForGet => this.selectedExchangeCoin.id !== coinForGet.id);
        },
        filterListCoins(type, name) {
            if (type === 'coinsForExchange') {
                this.listCoinsForExchangeCopy = this.listCoinsForExchange.filter(coinForExchange => this.selectedGetCoin.id !== coinForExchange.id && (coinForExchange.shortName.toLowerCase().includes(name.toLowerCase()) || coinForExchange.name.toLowerCase().includes(name.toLowerCase())));

                return;
            }

            if (type === 'coinsForGet') {
                this.listCoinsForGetCopy = this.listCoinsForGet.filter(coinForGet => this.selectedExchangeCoin.id !== coinForGet.id && (coinForGet.shortName.toLowerCase().includes(name.toLowerCase()) || coinForGet.name.toLowerCase().includes(name.toLowerCase())));
            }
        },
        changeExchangeValue() {
            this.exchangeInputValue = this.exchangeInputValue.replace(/[^.\d]+/g, "");
            this.updateExchangeValue(this.exchangeInputValue);
            this.calculationExchange('coinsForExchange');
        },
        changeGetValue() {
            this.getInputValue = this.getInputValue.replace(/[^.\d]+/g, "");
            this.updateGetValue(this.getInputValue);
            this.calculationExchange('coinsForGet');
        },
        async calculationExchange(type) {
            if (type === 'coinsForExchange') {
                const response = await fetch(`https://api.coinconvert.net/convert/${this.selectedExchangeCoin.shortName.toLowerCase()}/${this.selectedGetCoin.shortName.toLowerCase()}?amount=${this.exchangeValue}`);
                const data = await response.json();
                var plus = (data[this.selectedGetCoin.shortName] / 100) * 3;

                this.getInputValue = data[this.selectedGetCoin.shortName] + plus;
                this.updateGetValue(data[this.selectedGetCoin.shortName]);
            }
            if (type === 'coinsForGet') {
                const response = await fetch(`https://api.coinconvert.net/convert/${this.selectedGetCoin.shortName.toLowerCase()}/${this.selectedExchangeCoin.shortName.toLowerCase()}?amount=${this.getValue}`);
                const data = await response.json();
                var minus = (data[this.selectedExchangeCoin.shortName] / 100) * 3;
                this.exchangeInputValue = data[this.selectedExchangeCoin.shortName] - minus;
                this.updateExchangeValue(data[this.selectedExchangeCoin.shortName]);
            }
        },
        async afterCreateOrder() {
            if (!this.emailValidator.test(this.userData.email)) {
                this.errors.email.valid = false;
            }

            if (!this.userData.wallet) {
                this.errors.wallet.valid = false;
            }

            if (this.errors.email.valid && this.errors.wallet.valid && this.isAgree) {
                const order = await this.createOrder();
                this.updateUserEmail(null);
                this.updateUserWallet(null);
                router.push(`claim/${order.id}`);
            }
        },
    },
    computed: {
        ...mapState([
            'selectedExchangeCoin',
            'selectedGetCoin',
            'exchangeValue',
            'getValue',
            'showAccountDetailsForm',
            'listCoinsForExchange',
            'listCoinsForGet',
            'userData',
        ]),
    },
    beforeMount() {
        const timer = setInterval(() => {
            if (this.listCoinsForExchange.length > 0 && this.listCoinsForGet.length > 0) {
                const minExchangeValue = parseFloat(this.listCoinsForExchange[0].minValue);
                this.updateSelectedExchangeCoin(this.listCoinsForExchange[0]);
                this.updateSelectedGetCoin(this.listCoinsForGet[1]);
                this.updateExchangeValue(minExchangeValue);
                this.exchangeInputValue = minExchangeValue;
                this.filterListsCoinsAfterSelectCoin();
                this.calculationExchange('coinsForExchange');
                clearInterval(timer);
            }
        }, 500);
    },
    mounted() {
        const elem = document.getElementById('cryptocurrency-exchange__calculator-input-exchange');
        

        document.addEventListener('click', (e) => {
            if (!elem.contains(e.target)) {
                const minValue = parseFloat(this.selectedExchangeCoin.minValue);
                
                if (minValue > this.exchangeInputValue) {
                    this.updateExchangeValue(minValue);
                    this.exchangeInputValue = minValue;
                    this.calculationExchange('coinsForExchange');
                }
            }
        });
    }
}
</script>
