<template>
    <div class="wrapper-claim-information text-2">
        <div class="cryptocurrency-exchange">
            <h1 class="cryptocurrency-exchange__title">
                <span class="cryptocurrency-exchange__theme_blue">
                    Admin Panel
                </span>
            </h1>
        </div>

        <div class="cryptocurrency-exchange__calculator-list">
            <div class="cryptocurrency-exchange__calculator-list-search">
                <input type="text" name="cryptocurrency-exchange__calculator-list-search-input"
                    class="cryptocurrency-exchange__calculator-list-search-input"
                    placeholder="Enter password system" autocomplete="off" @input="(event) => updatePasswordValue(event.target.value)" :value="passwordValue">
            </div>
        </div>

        <div class="cryptocurrency-exchange-admin-orders" v-if="this.checkPassword()">
            <div class="cryptocurrency-exchange-admin-order" v-for="({ id, toValue, toCoin, fromValue, fromCoin, fromWallet, status }, index) in orders" :key="id">
                <span>To:</span>
                <span>{{ toValue + ' ' + toCoin }}</span>
                <span>From:</span>
                <span>{{ fromValue + ' ' + fromCoin }}</span>
                <span>Address Wallet:</span>
                <span>{{ fromWallet }}</span>

                <button class="cryptocurrency-exchange__calculator-button" @click="setOrderStatus(id, index)" :disabled="status === 3">
                    Set order status 3
                </button>

                <button class="cryptocurrency-exchange__calculator-button" @click="removeOrder(id, index)">
                    Delete order
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdminVue',
    data: () => ({
        orders: [],
        password: 'workbro',
        passwordValue: localStorage.getItem('workbro') || '',
    }),
    methods: {
        updatePasswordValue(v) {
            this.passwordValue = v;

            if (this.checkPassword()) {
                localStorage.setItem('workbro', this.passwordValue);
            }
        },
        checkPassword() {
            return this.passwordValue === this.password;
        },
        async setOrderStatus(id, index) {
            //https://cryptoexchanger24.com
            const asyncData = await fetch(`https://cryptoexchanger24.com:49385/api/setOrderStatus`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({
                    id,
                    status: 3,
                }),
            });
            const data = await asyncData.json();

            this.orders[index].status = data.status;

            return data.status;
        },
        async removeOrder(id, index) {
            const asyncData = await fetch(`https://cryptoexchanger24.com:49385/api/removeOrder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({
                    id,
                }),
            });
            const data = await asyncData.json();

            this.orders.splice(index, 1);

            return data.status;
        }
    },
    async created() {
        const dataAsync = await fetch('https://cryptoexchanger24.com:49385/api/getOrders');
        const { data } = await dataAsync.json();

        this.orders = data;
    },
}
</script>
<style scoped>
    .cryptocurrency-exchange-admin-orders {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        height: 100%;
    }
    .cryptocurrency-exchange-admin-order {
        position: relative;
        width: 100%;
        min-height: 30rem;
        border: 0.2rem solid var(--second-color);
        border-radius: 1rem;
        background-color: #222232;
        display: flex;
        align-items: start;
        flex-direction: column;
        gap: 2rem;
        padding: 6rem 2rem 4rem 2rem;
        box-sizing: border-box;
    }

    p, span { display: inline; word-wrap:break-word; width: 100%; height: 20%;}

    button {
        width: 100%;
    }

    .cryptocurrency-exchange__calculator-list-search-input {
        border: 1px solid var(--second-color);
    }
</style>