<template>
    <div class="wrapper-cryptocurrency-exchange-rules">
        <h1 class="cryptocurrency-exchange__title">
            <span class="cryptocurrency-exchange__theme_blue">
                Currency exchange terms
            </span>
        </h1>

        <span>
            This offer contains the main terms, conditions, and rules according to which cryptoexchanger24.com provides services to its customers. Before using cryptoexchanger24 exchange service, the user should agree to all of the following terms and conditions. Only by accepting all the conditions with no exceptions, the user can proceed with using the service. The list of exchange rules is publicly available on cryptoexchanger24.com.
        </span>

        <span class="text-2">
            Terms and conditions of services provided by cryptoexchanger24
        </span>

        <div class="cryptocurrency-exchange-questions">
            <details class="cryptocurrency-exchange-question">
                <summary class="cryptocurrency-exchange-question__title">
                    <span>
                        1. Key definitions, concepts and terms
                    </span>
                    <svg class="cryptocurrency-exchange-question__title-arrow-icon"
                        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                        <path id="XMLID_225_"
                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                                c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                                s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                    </svg>
                </summary>

                <div class="cryptocurrency-exchange-question__content">
                    <ol class="cryptocurrency-exchange-question__content-items">
                        <li class="cryptocurrency-exchange-question__content-item">
                            1.1. cryptoexchanger24.com service (hereinafter referred to as the Service) is a service that provides services to individuals and legal entities in terms of exchanging, buying or selling electronic currency. These services are carried out through a special software package available at https://cryptoexchanger24.com/.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            1.2. The User is any natural person who has agreed with all the conditions offered by the Service and has acceded to this agreement.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            1.3. Payment system - a software package created by a third party, through which digital currency units, electronic money and other obligations are accounted for, mutual settlements of users are organized, etc. Under this agreement, the key payment systems are: Yandex.Money, BTC, PerfectMoney, QIWI, Payeer, etc. Legal entities and individuals can always find out the full list of all payment systems on the cryptoexchanger24.com website.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            1.4. Electronic money (electronic currency) is monetary or other obligations that arise between persons using this currency and those who created it. The mentioned obligations are in digital form.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            1.5. Operations (payments) is the transfer of digital currency (electronic money) from the sender's account to the recipient's account.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            1.6. Application - information submitted by the User using the means of the Service in electronic form, indicating his intention to use the services of the Service on the terms proposed by the Service and specified in the parameters of the application.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            1.7. The outgoing electronic currency is the currency that the user has and wants to exchange or sell, and receive other electronic currency or money in a different form.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            1.8. The received currency is the currency that the user of the service receives as a result of the exchange, transferring the outgoing currency of the service.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            1.9. Parties are all users who unconditionally accepted the rules specified in this agreement and decided to use the service, as well as the cryptoexchanger24.com service itself.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            1.10. Currency exchange is a transaction for the exchange of electronic currency of various payment systems.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            1.11. Reserves of electronic currencies - the amount of funds available to the Service of Electronic currencies or funds for the performance of services. The amounts of reserves are indicated on the website of the Service on the main page.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            1.12. The rate is the ratio of one electronic currency in relation to another in the process of making an exchange.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            1.13. Website of the Service – https://cryptoexchanger24.com/.
                        </li>
                    </ol>
                </div>
            </details>
            <details class="cryptocurrency-exchange-question">
                <summary class="cryptocurrency-exchange-question__title">
                    <span>
                        2. Subject of the agreement. The order when the agreement comes into force
                    </span>
                    <svg class="cryptocurrency-exchange-question__title-arrow-icon"
                        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                        <path id="XMLID_225_"
                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                                c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                                s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                    </svg>
                </summary>

                <div class="cryptocurrency-exchange-question__content">
                    <ol class="cryptocurrency-exchange-question__content-items">
                        <li class="cryptocurrency-exchange-question__content-item">
                            2.1. The subject of this agreement is the provision of a number of services to cryptoexchanger24.com users, namely
                            <ul class="cryptocurrency-exchange-question__content-subitems">
                                <li class="cryptocurrency-exchange-question__content-item">
                                    2.1.1. Services for the exchange of one electronic currency for another or for money in a different form.
                                </li>
                                <li class="cryptocurrency-exchange-question__content-item">
                                    2.1.2. Services for the sale of electronic money.
                                </li>
                                <li class="cryptocurrency-exchange-question__content-item">
                                    2.1.3. Services for the acquisition of electronic money.
                                </li>
                            </ul>
                        </li>

                        <li class="cryptocurrency-exchange-question__content-item">
                            2.2. Acceptance of the terms of this agreement by the user is carried out upon:
                            <ul class="cryptocurrency-exchange-question__content-subitems">
                                <li class="cryptocurrency-exchange-question__content-item">
                                    2.2.1. Filling out the application form for the exchange and its further direction for execution to the service.
                                </li>
                                <li class="cryptocurrency-exchange-question__content-item">
                                    2.2.2. Registration on the site cryptoexchanger24.com.
                                </li>
                            </ul>
                        </li>

                        <li class="cryptocurrency-exchange-question__content-item">
                            2.3. When forming an application for an exchange, the user confirms his agreement with all the rules of the exchange and confirms his intention to use the services of the service on the conditions offered to him. 
                            <ul class="cryptocurrency-exchange-question__content-subitems">
                                <li class="cryptocurrency-exchange-question__content-item">
                                    2.3.1. This agreement comes into force as soon as the details of the cryptoexchanger24.com service receive funds from the user for further crediting him with electronic currency or money in the amount provided for by the terms of the application.
                                </li>
                                <li class="cryptocurrency-exchange-question__content-item">
                                    2.3.2. As soon as the funds are received on the details specified by the user, the current agreement automatically loses its validity. Also, this action occurs when the user exchange request is cancelled.
                                </li>
                            </ul>
                        </li>

                        <li class="cryptocurrency-exchange-question__content-item">
                            2.4. By accepting this agreement in electronic form, each of the parties thereby recognizes its legal force, equivalent to a similar agreement in writing. 
                        </li>

                        <li class="cryptocurrency-exchange-question__content-item">
                            2.5. Any changes to the current agreement can be made by the cryptoexchanger24.com service without the need to notify the user of such changes. However, this does not cancel the obligation of the service to post the current version of the agreement on the cryptoexchanger24.com website. 
                        </li>
                    </ol>
                </div>
            </details>
            <details class="cryptocurrency-exchange-question">
                <summary class="cryptocurrency-exchange-question__title">
                    <span>
                        3. Cost of services. Subject of the agreement
                    </span>
                    <svg class="cryptocurrency-exchange-question__title-arrow-icon"
                        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                        <path id="XMLID_225_"
                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                                c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                                s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                    </svg>
                </summary>

                <div class="cryptocurrency-exchange-question__content">
                    <ol class="cryptocurrency-exchange-question__content-items">
                        <li class="cryptocurrency-exchange-question__content-item">
                            3.1. The subject of the current agreement are the services of the service in terms of the sale, purchase or exchange of electronic currency of various payment systems. All of the above operations can be carried out solely on the initiative of the user and only if he agrees with all the terms of the agreement. 
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">3.2. For the services provided to the user, the latter pays the service a commission, the amount of which is displayed in the exchange request. This remuneration is paid by the user only after he agrees to the exchange.</li>
                        <li class="cryptocurrency-exchange-question__content-item">3.3. The amount of the commission fee of the cryptoexchanger24.com service for the above actions is reflected in the application in the form of an exchange rate and is confirmed by the user on one of the pages of the user interface.</li>
                        <li class="cryptocurrency-exchange-question__content-item">3.4. All information about the current exchange rate, methods of exchange and the amount of money transferred and received by the user, the latter can find out at one of the stages of filling out the application.</li>
                        <li class="cryptocurrency-exchange-question__content-item">3.5. The amount of payment for the services of the service, the purchase or sale of electronic assets or electronic money, is included in the exchange rate of each individual exchange direction.</li>
                    </ol>
                </div>
            </details>
            <details class="cryptocurrency-exchange-question">
                <summary class="cryptocurrency-exchange-question__title">
                    <span>
                        4. Conditions under which the cryptoexchanger24.com service provides its services to users
                    </span>
                    <svg class="cryptocurrency-exchange-question__title-arrow-icon"
                        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                        <path id="XMLID_225_"
                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                                c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                                s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                    </svg>
                </summary>

                <div class="cryptocurrency-exchange-question__content">
                    <ol class="cryptocurrency-exchange-question__content-items">
                        <!-- <li class="cryptocurrency-exchange-question__content-item">
                            Without these verifications, fraudsters can use the service as a platform for money laundering and terrorism financing. Then the service itself will be held liable for that. This is why exchanges and other major cryptocurrency companies implement AML regulations and conduct regular KYC verifications.
                        </li> -->
                        <li class="cryptocurrency-exchange-question__content-item">4.1. Sending an application for the exchange of electronic money by the user to the address of the service is equivalent to ordering the services of cryptoexchanger24.com.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.2. The user interface hosted on the cryptoexchanger24.com website makes it possible to manage the exchange and receive the most up-to-date data regarding the provision of services by the service.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.3. The processing of user requests is carried out in strict accordance with the privacy policy described in clause 5 of the current agreement, and in addition, the policy on combating illegal transactions and money laundering specified in clause 6 of this agreement.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.4. Accounting for all transactions carried out with electronic money is carried out in strict accordance with the rules, regulations, as well as the format of existing payment systems.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.5. Any completed operation carried out by the Service at the request of the User is considered irrevocable, i.e. cannot be canceled by the User after its completion - receipt by the User of what is due to him under previously accepted terms of the transaction.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.6. If more than 60 minutes have passed since the acceptance of the application, but the funds from the user have not been credited to the service account, the agreement will be automatically terminated and become invalid. Under the above conditions, the service has the right to terminate the agreement unilaterally, without informing the user about it.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.7. In case of termination of the agreement, Electronic units received after the above period shall be returned to the sender's details within the next 48 hours. When making a return, all commission expenses for the transfer of electronic units are made from the funds received at the expense of the User. The Service is not responsible for possible delays in the return, if they arose through no fault of the Service.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.8. The Service has the right to unilaterally terminate the agreement if the amount of the electronic currency declared by the user and its actually transferred amount differ. In this case, the service has the right to refuse to provide the service to the user, with the obligatory return of the previously transferred funds within 48 hours. In this case, all commission costs will be deducted from the user's funds. If there are some delays in the return of money, but they are not related to the actions of the service, the latter will not be responsible for them.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.9. If the money equivalent is not sent to the details specified by the User within 48 hours from the date of receipt of payment from the User, in the absence of reasons for blocking the Electronic Units received at the request of the User from the Service, the User may demand termination of the agreement by canceling his application and returning the electronic units in full.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.10. All requests related to the cancellation of the application are fulfilled by the service only if, at the time of receipt of this request, the service has not sent funds according to the application to the details specified by the user.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.11. As soon as the service receives a request to cancel the application from the client, it will refund the funds to the user within 24 hours. If delays occur through no fault of the service, the latter is not responsible for them.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.12. In order to fulfill its obligations, the service has the right to involve third-party performers in the process.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.13. The service reserves the right to unilaterally cancel the transaction and return the funds, taking into account the commission to the user's details. At the same time, the service is not obliged to inform the user of the reason for refusal to provide the service.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.14. If the user violates one or more clauses of this agreement, the service reserves the right to refuse service to the user.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.15. The service will notify the client about the denial of service by e-mail or in jivo chat. After that, cryptoexchanger24.com will return to the user the earlier funds on request. This return is made within 48 hours. In this case, the commission costs are deducted from the user's funds to be returned. If due to the fault of the service in the process of return there are delays, only in this case he is responsible for them.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.16. All subsequent Applications created by the User after the service refuses to further service the User are automatically frozen, the return in this case will be made within 30 calendar days.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.17. The cryptoexchanger24.com service reserves the right to delay the execution of the exchange operation if it needs to identify the user's identity. Such identification can be carried out by means of a telephone call or by other methods that are not contrary to applicable law.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.18. As soon as the user confirms his intention to use the services of cryptoexchanger24.com, he automatically confirms that all funds participating in the process of buying, selling or exchanging funds belong to him legally and are not obtained by illegal means.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.19. If the tax legislation provides for the payment of taxes and other fees, the user undertakes to pay them independently.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.20. Conditions of some PS: When making a transaction on Ethereum, Litecoin and Bitcoin cryptocurrencies, the user must take into account that such an application will be accepted for execution only after 1 successful confirmation of the transaction.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.21. By agreeing to the terms of the current agreement, the user automatically confirms that he is not involved in: Transactions related to money laundering Transactions that involve the receipt of income as a result of terrorist and criminal activities trade transactions with countries with which international trade is prohibited by applicable law Transactions that involve the receipt of income from any other illegal activity.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.22. If, through the fault of the client, the confirmation of the application for the purchase, sale or exchange of cryptocurrency was delayed for a period exceeding 8 hours, the cryptoexchanger24.com service has the right to require the client to re-create the application for the exchange. In addition, in such cases, the service reserves the right to return the funds to the user, with the obligatory deduction of the commission of the wallet of the cryptocurrency in which the transaction is made.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.23. The service has the right to terminate the agreement unilaterally if the client has violated the procedure for using the service. 4.23.1 The presence in the actions of the client of signs of a violation of the procedure for using the service is determined at the discretion of the service.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.24. If the agreement is terminated unilaterally, the service refunds the funds to the sender's details within 30 calendar days.</li>
                        <li class="cryptocurrency-exchange-question__content-item">4.25. The service has the right to request any additional information about the payment made by the client on the application, such as: a check of the payment made on the application, a screenshot of the transaction made as payment for the application created by the client, TXID of the transaction, etc.</li>
                    </ol>
                </div>
            </details>
            <details class="cryptocurrency-exchange-question">
                <summary class="cryptocurrency-exchange-question__title">
                    <span>
                        5. Privacy policy
                    </span>
                    <svg class="cryptocurrency-exchange-question__title-arrow-icon"
                        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                        <path id="XMLID_225_"
                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                                c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                                s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                    </svg>
                </summary>

                <div class="cryptocurrency-exchange-question__content">
                    <ol class="cryptocurrency-exchange-question__content-items">
                        <!-- <li class="cryptocurrency-exchange-question__content-item">
                            - 0-25% - safe wallet/transaction;
                        </li> -->
                        <li class="cryptocurrency-exchange-question__content-item">5.1. In order to carry out the operation of exchanging, buying or selling electronic money or cryptocurrency, the service asks the user for personal data, which it undertakes to store in encrypted form and not transfer to third parties, in addition to the situations mentioned in clauses 5.4, 5.5 and 6.5 of this agreement.</li>
                        <li class="cryptocurrency-exchange-question__content-item">5.2. Transactions based on requests received from clients, as well as the transfer of client data from them to the service, are carried out via a special encrypted SSL channel (key length is 256 bits).</li>
                        <li class="cryptocurrency-exchange-question__content-item">5.3. If this becomes necessary, the service reserves the right, on its own initiative, to collect additional information about the user, by any means that do not contradict the law. One way or another, but any information collected about the client will not be transferred to third parties. Exceptions are clauses 5.4, 5.5 and 6.5 of this agreement.</li>
                        <li class="cryptocurrency-exchange-question__content-item">5.4. The transfer of personal data of clients, as well as details of transactions carried out by them, can be transferred by a court decision, an official request received in writing from law enforcement agencies, as well as on the service’s own initiative. At the same time, the confidential status of such data will be preserved.</li>
                        <li class="cryptocurrency-exchange-question__content-item">5.5. The transfer of details of a transaction made by the user may be transferred by the cryptoexchanger24.com service at the official request of the Electronic Settlement System, if the purpose of such request is to conduct an internal investigation.</li>
                        <li class="cryptocurrency-exchange-question__content-item">5.6. Any data that the service receives from users is further stored in its database for 3 years after the last transaction was made by the user. The same is true for the details of the operations they carried out.</li>
                    </ol>
                </div>
            </details>
            <details class="cryptocurrency-exchange-question">
                <summary class="cryptocurrency-exchange-question__title">
                    <span>
                        6. Policy for combating illegal transactions and money laundering
                    </span>
                    <svg class="cryptocurrency-exchange-question__title-arrow-icon"
                        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                        <path id="XMLID_225_"
                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                                c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                                s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                    </svg>
                </summary>

                <div class="cryptocurrency-exchange-question__content">
                    <ol class="cryptocurrency-exchange-question__content-items">
                        <li class="cryptocurrency-exchange-question__content-item">6.1. According to the current international legislation, the cryptoexchanger24.com service follows a certain list of rules and performs procedures aimed at preventing the use of onemoment for transactions related to money laundering, that is, those transactions whose main purpose is to return illegally obtained securities or money to financial and economic market, to further provide and possession of legal methods. Also, the service stops all transactions that are illegal.</li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            6.2. In order to successfully prevent illegal transactions, the service has adopted some requirements for requests submitted by customers:
                            <ul class="cryptocurrency-exchange-question__content-subitems">
                                <li class="cryptocurrency-exchange-question__content-item">6.2.1. The recipient and sender of the payment on the application must not be different persons. Transfers to third parties are prohibited.</li>
                                <li class="cryptocurrency-exchange-question__content-item">6.2.2. All personal and contact data that the client of the service indicates in the application must be up-to-date at the time of filing such an application. The reliability of such data should not be questioned.</li>
                                <li class="cryptocurrency-exchange-question__content-item">6.2.3. Tickets created by users using anonymous connections will not be processed by the service. The creation of such applications is prohibited by cryptoexchanger24.com.</li>
                            </ul>
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">6.3. In order to stop possible illegal operations, cryptoexchanger24.com</li>
                        <li class="cryptocurrency-exchange-question__content-item">— The limits set depend on factors such as the user's country of residence, degree of identification, etc.</li>
                        <li class="cryptocurrency-exchange-question__content-item">— Applies its own system of auto-analysis of the client's actions and their transactions. At the same time, all transactions that seem suspicious to the service will be urgently stopped.</li>
                        <li class="cryptocurrency-exchange-question__content-item">— Always adheres to the generally accepted policy that says - “know who your client is“.</li>
                        <li class="cryptocurrency-exchange-question__content-item">— Mandatory checks the information received from users by all available means.</li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            6.4. The service has the full right not to fulfill user requests, but to freeze them if it is necessary to receive from the client copies of identification documents and copies of documents that confirm the legal origin of funds. Cases in which these documents may be requested: The User violated the conditions mentioned in clause 6.2. of this agreement The management of cryptoexchanger24.com has suspicions that the client is trying to use the service and its services in order to carry out illegal transactions or money laundering The execution of the user's application was suspended by the system responsible for preventing fraudulent transactions for execution, the latter undertakes to provide all the required documents to cryptoexchanger24.comThe Service has within 7 working days from the date of receipt of the request from the service, and if he refuses to do this, to cancel the application.
                            <ul class="cryptocurrency-exchange-question__content-subitems">
                                <li class="cryptocurrency-exchange-question__content-item">
                                    6.4.1. If the client refuses to provide documents to cryptoexchanger24.com upon request, the service has the right to refuse him further service.
                                </li>
                            </ul>
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">6.5. The Service has the right to refuse service to the client, and then transfer all information collected from the client and independently to law enforcement agencies if: The cryptoexchanger24.com Service detected transactions aimed at financing terrorism and money laundering, as well as involving any fraud. The cryptoexchanger24.com Service suspects the user that the documents provided by him are fake From law enforcement and other authorized authorities received information that the user of the service owns funds illegally. This also includes the receipt of any other information that makes it impossible to further fulfill the application from the client User actions are defined as those that can cause damage to cryptoexchanger24.com, regardless of the nature of this damage - material (physical), intangible, etc. .(hereinafter referred to as termination clauses 4.23, 4.23.1, 4.23.2) The actions of the client may harm the cryptoexchanger24.com software package or are aimed at gaining access to the service database (hereinafter referred to as termination clauses 4.23, 4.23.1, 4.23.2).</li>
                        <li class="cryptocurrency-exchange-question__content-item">6.6. The information received from users can be checked by the service in all ways.</li>
                    </ol>
                </div>
            </details>
            <details class="cryptocurrency-exchange-question">
                <summary class="cryptocurrency-exchange-question__title">
                    <span>
                        7. Limitation of Liability
                    </span>
                    <svg class="cryptocurrency-exchange-question__title-arrow-icon"
                        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                        <path id="XMLID_225_"
                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                                c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                                s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                    </svg>
                </summary>

                <div class="cryptocurrency-exchange-question__content">
                    <ol class="cryptocurrency-exchange-question__content-items">
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.1. The service provides its services 24 hours, 7 days a week, while cryptoexchanger24.com should not be confused with a banking organization, which it is not. The purpose of the service is to provide users with really high-quality services, which assume the smooth operation of all components of the software package.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.2. The service does not give any additional guarantees to its customers. All services are provided as is.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.3. By agreeing to use this service, you automatically agree that the entire scope of cryptoexchanger24.com's liability to you will be strictly limited to the funds that it receives from you cryptoexchanger24.com does not bear any extra responsibility to customers. And the responsibility of the user to cryptoexchanger24.com is also limited. Exceptions are the cases specified in clause 7.9.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.4. cryptoexchanger24.com specialists do their best to be available 24/7. However, at the same time, this mode of operation is not guaranteed, so cryptoexchanger24.com itself is not responsible for the losses that the user will receive due to the failure of the service.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.5. Failures, errors and delays in the process of making electronic transfers and banking transactions are also possible, and if for this reason the client did not receive any profit, cryptoexchanger24.com is not responsible for these losses.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.6. cryptoexchanger24.com is not responsible for lost income and losses that caused the client's high expectations in relation to the terms of service exchange and other subjective factors.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.7. If the client made a mistake when specifying the details, and the money went “in the wrong place”, the client himself, and not the service, is responsible for this.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.8. Also, the client is responsible for the timely payment of taxes and fees, provided for by the legislation of his state. The client will not receive any notifications about this from the service, because the service is not a tax agent.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.9. When submitting claims and lawsuits from the Service, the client undertakes to compensate for the losses incurred, only if the cause of such losses was not the fault of the service itself.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.10. The client undertakes not to conduct illegal and fraudulent transactions through cryptoexchanger24.com, and also agrees that he will be liable for all attempts to conduct such transactions in accordance with the law.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.11. No falsification of communication flows from the client of the service will occur, and the client guarantees this. In addition, he undertakes under no circumstances to attempt to interfere with the hardware and / or software, and not to perform any other actions that may subsequently lead to a stop of the cryptoexchanger24.com service.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.12. If the service detects that the client is trying to have a negative impact on the cryptoexchanger24.com program code or on communication flows, the request created by the client will be frozen and its execution will be suspended. Next, the service will take the measures mentioned in clause 6.5. of this agreement.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.13. If the failure of the service or the client to fulfill their own obligations was caused by force majeure factors (flood, fire, terrorist act, etc.), the parties will not be liable for them.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.14. Fin. institutions and electronic payment systems are limited to liability within the funds transferred to them by the client cryptoexchanger24.com is not one of the parties to the agreement between the user and the payment system, and therefore is not responsible for the fulfillment of the obligations of these parties.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.15. By accepting the terms of this agreement, the client confirms that he is not a tax resident of the United States or a citizen of this country.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.16. If the funds were received by the cryptoexchanger24.com service 3 months after the client created an exchange request, they are transferred to the cryptoexchanger24.com service, and this service decides how to dispose of these funds.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.17. If the funds were received by the cryptoexchanger24.com service, but an exchange request was not generated, the service has the right to dispose of these funds at its discretion.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.18. If the client specified erroneous details, in order to successfully complete the application, he should provide correct details to cryptoexchanger24.com4 within 3 months, otherwise the funds will be at the full disposal of the service.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.19. If the amount of funds specified in the application and actually provided by the client differs, cryptoexchanger24.com has the right to stop this exchange, and after the client contacts cryptoexchanger24.com, make an exchange at the rate that is relevant at the beginning of the transaction.
                        </li>
                    </ol>
                </div>
            </details>
            <details class="cryptocurrency-exchange-question">
                <summary class="cryptocurrency-exchange-question__title">
                    <span>
                        8. Dispute Resolution and Claims Procedure
                    </span>
                    <svg class="cryptocurrency-exchange-question__title-arrow-icon"
                        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                        <path id="XMLID_225_"
                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                                c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                                s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                    </svg>
                </summary>

                <div class="cryptocurrency-exchange-question__content">
                    <ol class="cryptocurrency-exchange-question__content-items">
                        <li class="cryptocurrency-exchange-question__content-item">
                            7.1. The service provides its services 24 hours, 7 days a week, while cryptoexchanger24.com should not be confused with a banking organization, which it is not. The purpose of the service is to provide users with really high-quality services, which assume the smooth operation of all components of the software package.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            8.1. Any disagreements regarding the services of cryptoexchanger24.com are resolved by negotiating between the management of cryptoexchanger24.com and the client, based on the clauses of the current agreement.
                            <ul class="cryptocurrency-exchange-question__content-subitems">
                                <li class="cryptocurrency-exchange-question__content-item">
                                    8.1.1. All claims from customers are accepted by cryptoexchanger24.com in electronic form by sending a message on the subject of disputes to the specified details (see “Contacts“).
                                </li>
                            </ul>
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            8.2. If, as a result of negotiations, the parties have not managed to resolve the conflict, they will continue to resolve it in accordance with the legislation in force at the registration address cryptoexchanger24.com.
                        </li>
                    </ol>
                </div>
            </details>
            <details class="cryptocurrency-exchange-question">
                <summary class="cryptocurrency-exchange-question__title">
                    <span>
                        9. Closing provisions
                    </span>
                    <svg class="cryptocurrency-exchange-question__title-arrow-icon"
                        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                        <path id="XMLID_225_"
                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                                c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                                s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                    </svg>
                </summary>

                <div class="cryptocurrency-exchange-question__content">
                    <ol class="cryptocurrency-exchange-question__content-items">
                        <li class="cryptocurrency-exchange-question__content-item">
                            9.1. cryptoexchanger24.com has the right to send up-to-date data on the exchange process to the e-mail specified by the client, as well as some other information, also that which is of an advertising nature.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            9.2. All information specified on the site cryptoexchanger24.com is the property of the service, and therefore is protected by copyright from unauthorized copying.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            9.3. Acceptance of the current agreement automatically means that the client of the service allows him to process personal data. By accepting this agreement, the client allows the service to copy, organize, store, clarify, accumulate, use, block and destroy the information provided by the client.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            9.4. If the client does not agree with the processing of personal data, he can withdraw his consent at any time.
                        </li>
                        <li class="cryptocurrency-exchange-question__content-item">
                            9.5. The User confirms that he has read all the provisions of this Agreement and unconditionally accepts them, otherwise the User cannot use the Service cryptoexchanger24.com.
                        </li>
                    </ol>
                </div>
            </details>
        </div>

        <span class="text-2">
            By the way! If something went wrong, for greater efficiency, write to us in the online chat, the contact button is in the lower right part of the screen. Our experts will be happy to answer any of your questions.
        </span>
    </div>
</template>

<script>
export default {
    name: 'ExchangeRulesVue',
    data: () => ({}),
    methods: {},
}
</script>
<style scoped>
    .wrapper-cryptocurrency-exchange-rules {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        font-size: 1.6rem;
    }

    .cryptocurrency-exchange-question__content-subitems {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 2rem 0 0 2rem;
    }

    .gap-0 {
        gap: 0;
    }
</style>