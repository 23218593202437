<template>
    <div class="cryptocurrency-exchange-questions">
        <h1 class="cryptocurrency-exchange__title">
            <span class="cryptocurrency-exchange__theme_blue">
                FAQ
            </span>
        </h1>

        <details class="cryptocurrency-exchange-question">
            <summary class="cryptocurrency-exchange-question__title">
                <span>
                    What is a referral program and how can you make money with it?
                </span>
                <svg class="cryptocurrency-exchange__calculator-select-icon cryptocurrency-exchange-question__title-arrow-icon"
                    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                    <path id="XMLID_225_"
                        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                </svg>
            </summary>

            <div class="cryptocurrency-exchange-question__content">
                <ol class="cryptocurrency-exchange-question__content-items">
                    <li class="cryptocurrency-exchange-question__content-item">
                        A referral program is a program where you earn interest, from the exchanges made
                        by the customers you refer. It means that we are ready to share the profit with
                        you. Earn money along with us. How can you do it? Tell about us, and share your
                        personal referral link with your friends and acquaintances. Put our ads on your
                        website and get a percentage from the exchange operations of the referred users.
                        How to join the program? It's quite easy. First, you need to sign up on our
                        website. You will receive your login and password by e-mail. Your referral link
                        will appear in your personal account. The main point is that the referral
                        program only works if your Referral follows your referral link.
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        How to join the program? It's quite easy. First, you need to sign up on our
                        website. You will receive your login and password by e-mail. Your referral link
                        will appear in your personal account. The main point is that the referral
                        program only works if your Referral follows your referral link.
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        Your commission depends on the number of customers you refer:
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        0,5% from 1 to 20 referrals
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        0,7% from 21 to 70 referrals
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        0.9% over 71 referrals
                    </li>
                    <li class="cryptocurrency-exchange-question__content-item">
                        Your commission is calculated depending on the amount of currency exchange of
                        your referral. In case the service has no income from the exchange, your
                        affiliate commission cannot be credited. To withdraw your affiliate commission,
                        the number of unique clients you attracted must exceed 3. Affiliate payments can
                        be paid by any method provided by the exchange service. The minimum withdrawal
                        amount of your affiliate commission is 3 USD or 150 RUB.
                    </li>
                </ol>
            </div>
        </details>
        <details class="cryptocurrency-exchange-question">
            <summary class="cryptocurrency-exchange-question__title">
                <span>
                    Where can I track my application status?
                </span>
                <svg class="cryptocurrency-exchange__calculator-select-icon cryptocurrency-exchange-question__title-arrow-icon"
                    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                    <path id="XMLID_225_"
                        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                </svg>
            </summary>

            <div class="cryptocurrency-exchange-question__content">
                <ol class="cryptocurrency-exchange-question__content-items">
                    <li class="cryptocurrency-exchange-question__content-item">
                        You can track your application status in your personal account in the 'applications' section (login and password are sent to your e-mail during registration or when you create your first application). We will also notify you about the status of your application by e-mail.
                    </li>
                </ol>
            </div>
        </details>
        <details class="cryptocurrency-exchange-question">
            <summary class="cryptocurrency-exchange-question__title">
                <span>
                    Why do you need confirmations in the bitcoin system?
                </span>
                <svg class="cryptocurrency-exchange__calculator-select-icon cryptocurrency-exchange-question__title-arrow-icon"
                    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
                    <path id="XMLID_225_"
                        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                </svg>
            </summary>

            <div class="cryptocurrency-exchange-question__content">
                <ol class="cryptocurrency-exchange-question__content-items">
                    <li class="cryptocurrency-exchange-question__content-item">
                        On receiving bitcoins, the recipient cannot use them right away. Once a transfer is made, it is sent to the Bitcoin system for execution, pending inclusion into the block and confirmation. The process of including a transfer in a block is called transaction confirmation. Inclusion in 1 block = 1 confirmation. When there are 2 or more confirmations, the transaction is considered confirmed, and cryptoexchanger24.com operators, in turn, process the application. This method is introduced for the protection against reuse of the same bitcoins.
                    </li>
                </ol>
            </div>
        </details>
    </div>
</template>

<script>
export default {
    name: 'FaqVue',
    data: () => ({}),
    methods: {},
}
</script>
<style>
</style>