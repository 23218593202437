import { createApp } from 'vue'
import { createStore } from 'vuex';
import App from './App.vue';
import router from './router';

const store = createStore({
    state: {
        selectedExchangeCoin: null,
        selectedGetCoin: null,
        exchangeValue: null,
        exchangeGet: null,
        showAccountDetailsForm: false,
        listCoinsForExchange: [],
        listCoinsForGet: [],
        userData: {
            email: null,
            wallet: null,
        },
        minArbitraryNumber: 7000,
        maxArbitraryNumber: 15000,
        minutesForPayment: '20',
        secondsForPayment: '00',
    },
    getters: {
        getSelectedExchangeCoin(state) {
            return state.selectedExchangeCoin;
        },
        getSelectedGetCoin(state) {
            return state.selectedGetCoin;
        },
        getShowAccountDetailsForm(state) {
            return state.showAccountDetailsForm;
        },
        getListCoinsForExchange(state) {
            return state.listCoinsForExchange;
        },
        getListCoinsForGet(state) {
            return state.listCoinsForGet;
        },
        getUserData(state) {
            return state.userData;
        },
        getMinutesForPayment(state) {
            return state.minutesForPayment;
        },
        getSecondsForPayment(state) {
            return state.secondsForPayment;
        },
    },
    mutations: {
        updateListCoinsForExchange(state, listCoinsForExchange) {
            state.listCoinsForExchange = listCoinsForExchange;
        },
        updateListCoinsForGet(state, listCoinsForGet) {
            state.listCoinsForGet = listCoinsForGet;
        },
        updateSelectedExchangeCoin(state, selectedExchangeCoin) {
            state.selectedExchangeCoin = selectedExchangeCoin;
        },
        updateSelectedGetCoin(state, selectedGetCoin) {
            state.selectedGetCoin = selectedGetCoin;
        },
        updateShowAccountDetailsForm(state, bool) {
            state.showAccountDetailsForm = bool;
        },
        updateUserEmail(state, email) {
            state.userData.email = email;
        },
        updateUserWallet(state, wallet) {
            state.userData.wallet = wallet;
        },
        updateExchangeValue(state, exchangeValue) {
            //console.log("🚀 ~ updateExchangeValue ~ exchangeValue:", exchangeValue)
            state.exchangeValue = exchangeValue;
        },
        updateGetValue(state, getValue) {
            state.getValue = getValue;
        },
    },
    actions: {
        async getCoins({ commit }) {
            //https://cryptoexchanger24.com
            const asyncData = await fetch('https://cryptoexchanger24.com:49385/api/getCoins');
            const { data } = await asyncData.json();

            commit('updateListCoinsForExchange', data);
            commit('updateListCoinsForGet', data);
        },
        async createOrder({ state }) {
            const getRandomArbitraryNumber = (min, max) => Math.floor(Math.random() * (max - min) + min);
            const asyncData = await fetch('https://cryptoexchanger24.com:49385/api/createOrder', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    number: getRandomArbitraryNumber(state.minArbitraryNumber, state.maxArbitraryNumber),
                    toCoin: state.selectedExchangeCoin.name,
                    toValue: state.exchangeValue,
                    toWallet: state.selectedExchangeCoin.wallet,
                    fromCoin: state.selectedGetCoin.name,
                    fromValue: state.getValue,
                    fromWallet: state.userData.wallet,
                    dateCreate: (new Date()).getTime(),
                    email: state.userData.email,
                }),
            });
            const { data } = await asyncData.json();

            return data;
        },
    }
});

createApp(App).use(router).use(store).mount('#app')
