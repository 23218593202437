import { createRouter, createWebHistory } from 'vue-router';
import MainVue from '@/components/MainVue.vue';
import ExchangeRulesVue from '@/components/ExchangeRulesVue.vue';
import AmlVue from '@/components/AmlVue.vue';
import FaqVue from '@/components/FaqVue.vue';
import ContactsVue from '@/components/ContactsVue.vue';
import ClaimVue from '@/components/ClaimVue.vue';
import AdminVue from '@/components/AdminVue.vue';

const routes = [
    {
        path: '/',
        component: MainVue,
        meta: {title: "asd"}
    },
    {
        path: '/rules',
        component: ExchangeRulesVue,
        meta: {title: "asd"}
    },
    {
        path: '/aml',
        component: AmlVue,
    },
    {
        path: '/faq',
        component: FaqVue,
    },
    {
        path: '/contacts',
        component: ContactsVue,
    },
    {
        path: '/claim/:id',
        component: ClaimVue,
    },
    {
        path: '/admin',
        component: AdminVue,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router
