<template>
    <div class="wrapper-claim-information text-2">
        <div class="cryptocurrency-exchange">
            <h1 class="cryptocurrency-exchange__title">
                <span class="cryptocurrency-exchange__theme_blue">
                    Application information № {{ order?.number }}
                </span>
            </h1>
        </div>
        <div class="claim-payment-steps">
            <div class="claim-payment-step">
                <div class="claim-payment-step-number"
                    :class="[compareOrderStatus('>=', 1) ? 'claim-payment-step-number-succsess' : '']">
                    <span>
                        1
                    </span>
                </div>
                <div class="claim-payment-step-description">
                    <span>
                        Waiting for payment
                    </span>
                </div>
            </div>

            <div class="claim-payment-step-line"
                :class="[compareOrderStatus('>=', 1) ? 'claim-payment-step-line-succsess' : '']">
            </div>

            <div class="claim-payment-step">
                <div class="claim-payment-step-number"
                    :class="[compareOrderStatus('>=', 2) ? 'claim-payment-step-number-succsess' : '']">
                    <span>
                        2
                    </span>
                </div>
                <div class="claim-payment-step-description">
                    <span>
                        Processing
                    </span>
                </div>
            </div>

            <div class="claim-payment-step-line"
                :class="[compareOrderStatus('=', 3) ? 'claim-payment-step-line-succsess' : '']"></div>

            <div class="claim-payment-step">
                <div class="claim-payment-step-number"
                    :class="[compareOrderStatus('=', 3) ? 'claim-payment-step-number-succsess' : '']">
                    <span>
                        3
                    </span>
                </div>
                <div class="claim-payment-step-description">
                    <span>
                        Final step
                    </span>
                </div>
            </div>
        </div>
        <div class="wrapper-claim-payment-timer" v-if="compareOrderStatus('=', 1)">
            <div class="claim-payment-timer">
                <span class="cryptocurrency-exchange__theme_blue">
                    {{ getTimerData() }}
                </span>
            </div>

            <span>
                Pay for the application before the end of this time
            </span>
        </div>

        <div class="wrapper-claim-payment-payment-information" v-if="compareOrderStatus('=', 1)">
            <div class="claim-payment-payment-information">
                <div class="claim-payment-payment-information-to-pay">
                    <span>
                        To pay
                    </span>

                    <div class="cryptocurrency-exchange__theme_blue overflow-x-auto">
                        {{ getToPayInformation() }}
                    </div>
                </div>

                <div class="wrapper-claim-payment-payment-information-payment-requisite">
                    <span>
                        Payment information
                    </span>

                    <div class="claim-payment-payment-information-payment-requisite overflow-x-auto">
                        <button class="btn-unset">
                            <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" name="icon-copy"
                                class="claim-payment-payment-information-payment-requisite-copy-icon">
                                <g clip-path="url(#clip0_2825_25373)">
                                    <path
                                        d="M22.2135 6.54004H13.6213C12.9524 6.54004 12.4102 7.08224 12.4102 7.75116C12.4102 8.42008 12.9524 8.96228 13.6213 8.96228H22.2135C22.8824 8.96228 23.4246 8.42008 23.4246 7.75116C23.4246 7.08224 22.8823 6.54004 22.2135 6.54004Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M22.2135 11.3848H13.6213C12.9524 11.3848 12.4102 11.927 12.4102 12.5959C12.4102 13.2648 12.9524 13.807 13.6213 13.807H22.2135C22.8823 13.807 23.4246 13.2648 23.4246 12.5959C23.4246 11.927 22.8824 11.3848 22.2135 11.3848Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M17.3152 16.2285H13.6213C12.9524 16.2285 12.4102 16.7707 12.4102 17.4396C12.4102 18.1086 12.9524 18.6508 13.6213 18.6508H17.3152C17.9841 18.6508 18.5263 18.1086 18.5263 17.4396C18.5263 16.7707 17.9841 16.2285 17.3152 16.2285Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M27.1286 16.4711C27.7976 16.4711 28.3398 15.9289 28.3398 15.26V4.84448C28.3398 2.17321 26.1665 0 23.4953 0H12.353C9.68326 0 7.51102 2.17079 7.50854 4.84C4.83733 4.84 2.66406 7.01321 2.66406 9.68447V26.1556C2.66406 28.8268 4.83727 31.0001 7.50854 31.0001H19.2563C21.9275 31.0001 24.1008 28.8269 24.1008 26.1556V26.1188C26.4872 25.8198 28.3397 23.7779 28.3397 21.312C28.3397 20.6431 27.7975 20.1009 27.1286 20.1009C26.4597 20.1009 25.9175 20.6431 25.9175 21.312C25.9175 22.6473 24.8311 23.7339 23.4958 23.7343L12.3536 23.737H12.353C11.7061 23.737 11.0979 23.4851 10.6404 23.0277C10.1828 22.5702 9.93078 21.9618 9.93078 21.3147V4.84448C9.93078 3.50887 11.0174 2.42224 12.353 2.42224H23.4953C24.8309 2.42224 25.9176 3.50887 25.9176 4.84448V15.2601C25.9175 15.9289 26.4597 16.4711 27.1286 16.4711ZM12.353 26.1591H12.3542L21.6786 26.1568C21.6778 27.4918 20.5915 28.5777 19.2563 28.5777H7.5086C6.173 28.5777 5.08636 27.4911 5.08636 26.1555V9.68435C5.08636 8.34875 6.173 7.26211 7.5086 7.26211V21.3147C7.5086 22.6089 8.01265 23.8256 8.92788 24.7406C9.84292 25.6555 11.0591 26.1591 12.353 26.1591Z"
                                        fill="currentColor"></path>
                                </g>
                                <defs>
                                    <clipPath>
                                        <rect width="31" height="31" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>

                        <span class="cryptocurrency-exchange__theme_blue">
                            {{ getToPaymentInformation() }}
                        </span>
                    </div>
                </div>
            </div>

            <button type="button" class="cryptocurrency-exchange__calculator-button"
                :disabled="getTimeForPay().timeForPayIsOver" @click="goToNextStatus(2)">
                I made the payment
            </button>
        </div>

        <div class="wrapper-claim-exchange-cards" v-if="compareOrderStatus('<=', 2)">
            <div class="claim-exchange-card">
                <span>
                    You send
                </span>

                <span>
                    {{ getToPayInformation() }}
                </span>
            </div>

            <div class="wrapper-claim-exchange-cards-icon" v-if="compareOrderStatus('=', 2)">
                <svg class="claim-exchange-cards-icon" viewBox="-3 0 32 32" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="icomoon-ignore">
                    </g>
                    <path
                        d="M13.11 29.113c7.243 0 13.113-5.871 13.113-13.113s-5.87-13.113-13.113-13.113c-7.242 0-13.113 5.871-13.113 13.113s5.871 13.113 13.113 13.113zM13.11 3.936c6.652 0 12.064 5.412 12.064 12.064s-5.412 12.064-12.064 12.064c-6.653 0-12.064-5.412-12.064-12.064s5.411-12.064 12.064-12.064z"
                        fill="#4d5fc1">

                    </path>
                    <path
                        d="M13.906 21.637l0.742 0.742 6.378-6.379-6.378-6.379-0.742 0.742 5.112 5.112h-12.727v1.049h12.727z"
                        fill="#4d5fc1">

                    </path>
                </svg>
            </div>

            <div class="claim-exchange-card">
                <span>
                    You get
                </span>

                <span>
                    {{ getFromPayInformation() }}
                </span>

                <span>
                    To card/wallet
                </span>

                <div class="claim-exchange-card-field">
                    <div>
                        {{ getFromPaymentInformation() }}
                    </div>
                </div>
            </div>
        </div>

        <div class="claim-information-info-two-step" v-if="compareOrderStatus('=', 2)">
            <span>
                After sending, funds do not always arrive instantly.
            </span>
            <span>
                It may take some time for funds to be credited to our account.
            </span>
        </div>

        <div class="claim-information-info-two-step" v-if="compareOrderStatus('=', 3)">
            <span>
                Dear customer, thank you for using our exchanger! We have received your payment, please wait for the
                money to be transferred to the details you provided.
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useRoute } from 'vue-router';

export default {
    name: 'ClaimVue',
    data: () => ({
        order: null,
        timeForPay: {
            timeForPayMinutes: null,
            timeForPaySeconds: null,
            timeForPayIsOver: null,
        },
    }),
    methods: {
        setTimeForPay(timeForPayMinutes, timeForPaySeconds, timeForPayIsOver) {
            this.timeForPay.timeForPayMinutes = timeForPayMinutes;
            this.timeForPay.timeForPaySeconds = timeForPaySeconds;
            this.timeForPay.timeForPayIsOver = timeForPayIsOver;
        },
        getTimeForPay() {
            return this.timeForPay;
        },
        getTimerData() {
            const timeForPay = this.getTimeForPay();

            return `${timeForPay.timeForPayMinutes} : ${timeForPay.timeForPaySeconds}`;
        },
        compareOrderStatus(operator, status) {
            const orderStatus = !this.order
                ? null
                : this.order.status;

            switch (operator) {
                case '=':
                    return orderStatus === status;
                case '>':
                    return orderStatus > status;
                case '<':
                    return orderStatus < status;
                case '>=':
                    return orderStatus >= status;
                case '<=':
                    return orderStatus <= status;
            }
        },
        addMinutes(date, minutes) {
            //console.log("🚀 ~ addMinutes ~ date:", date)
            return new Date(date.getTime() + minutes * 60000);
        },
        getTimeRemaining(endtime) {
            const total = Date.parse(endtime) - Date.parse(new Date());
            const seconds = Math.floor((total / 1000) % 60);
            const minutes = Math.floor((total / 1000 / 60) % 60);
            const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
            const days = Math.floor(total / (1000 * 60 * 60 * 24));

            return { total, days, hours, minutes, seconds };
        },
        initializeTimer(endtime) {
            //console.log("🚀 ~ initializeTimer ~ endtime:", endtime)
            const timeinterval = setInterval(() => {
                const t = this.getTimeRemaining(endtime);

                if (t.total <= 0) {
                    this.setTimeForPay('00', '00', true);
                    clearInterval(timeinterval);
                } else {
                    this.setTimeForPay(
                        t.minutes.toString().length > 1
                            ? t.minutes.toString()
                            : `0${t.minutes}`,
                        t.seconds.toString().length > 1
                            ? t.seconds.toString()
                            : `0${t.seconds}`, false
                    );
                }
            }, 1000);
        },
        getToPayInformation() {
            if (!this.order) {
                return '';
            }

            return `${this.order.toValue} ${this.order.toCoin}`;
        },
        getToPaymentInformation() {
            if (!this.order) {
                return '';
            }

            return this.order.toWallet;
        },
        getFromPayInformation() {
            if (!this.order) {
                return '';
            }

            return `${this.order.fromValue} ${this.order.fromCoin}`;
        },
        getFromPaymentInformation() {
            if (!this.order) {
                return '';
            }

            return this.order.fromWallet;
        },
        setOrder(order) {
            this.order = order;
        },
        runOrderStatusCheck() {
            const timeinterval = setInterval(async () => {
                const status = await this.getOrderStatus(this.order.id);

                if (status === 3) {
                    this.order.status = status;
                    clearInterval(timeinterval);
                }
            }, 10000);
        },
        async getOrder() {
            const router = useRoute();
            //https://cryptoexchanger24.com
            const asyncData = await fetch(`https://cryptoexchanger24.com:49385/api/getOrder/${router.params.id}`);
            const { data } = await asyncData.json();

            return data;
        },
        async setOrderStatus(status) {
            const asyncData = await fetch(`https://cryptoexchanger24.com:49385/api/setOrderStatus`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({
                    id: this.order.id,
                    status,
                }),
            });
            const data = await asyncData.json();

            return data.status;
        },
        async getOrderStatus(id) {
            const asyncData = await fetch(`https://cryptoexchanger24.com:49385/api/getOrderStatus/${id}`);
            const data = await asyncData.json();

            return data.status;
        },
        async goToNextStatus(status) {
            const data = await this.setOrderStatus(status);

            this.order.status = data;

            if (this.compareOrderStatus('=', 2)) {
                this.runOrderStatusCheck();
            }
        },
        ...mapGetters([
            'getMinutesForPayment',
            'getSecondsForPayment',
        ]),
    },
    async created() {
        this.setTimeForPay(this.getMinutesForPayment(), this.getSecondsForPayment(), true);
        this.setOrder(await this.getOrder());
        this.initializeTimer(this.addMinutes(new Date(parseInt(this.order.dateCreate)), 20));

        if (this.compareOrderStatus('=', 2)) {
            this.runOrderStatusCheck();
        }
    },
}
</script>
<style>
.claim-payment-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 100%;
}

.gap-3-2 {
    gap: 3.2rem;
}
</style>