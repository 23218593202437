<template>
    <div class="cryptocurrency-exchange cryptocurrency-exchange-info">
        <h1 class="cryptocurrency-exchange__title">
            <span class="cryptocurrency-exchange__theme_blue">
                Contact us
            </span>
        </h1>

        <div class="wrapper-contact-us">
            <div class="contact-us">
                <span class="cryptocurrency-exchange__title">
                    Contacts
                </span>

                <span class="text-2">
                    Technical support
                </span>

                <span class="cryptocurrency-exchange__theme_blue">
                    cryptoexchanger24.com@gmail.com
                </span>

                <span class="text-2">
                    Cooperation and PR
                </span>

                <span class="cryptocurrency-exchange__theme_blue">
                    cryptoexchanger24.com@gmail.com
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactsVue',
    data: () => ({}),
    methods: {},
}
</script>
<style>
.wrapper-contact-us {
    display: flex;
    gap: 2.3rem;
    justify-content: center;
}
.contact-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    flex: 0 1 28.4rem;
    min-width: 26.5rem;
    padding: 1.6rem;
    border: 1px solid var(--second-color);
    border-radius: 1rem;
    height: 32rem;
}
</style>